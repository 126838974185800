import store from '../store'
export default {
  data() {
    return {
    }
  },
  methods: {
    // 路由跳转
    jump: function (to, query, isReplace) {
      if (typeof to === 'number') {
        this.$router.go(to);
        return;
      }
      if (isReplace) {
        this.$router.replace({ path: to, query: query || {} });
        return;
      }
      this.$router.push({ path: to, query: query || {} });
    },
    // 获取路由信息
    getJump: function (name, value) {
      value = typeof value === 'undefined' ? '' : value;
      return this.$route.query[name] || value;
    },
    // 获取域名
    getUrlDomain : function(){
      var _url = document.location.href;
      var newUrl = "";
      var _index = _url.indexOf("/");
      var _httpIndex = "";
      if(_index >= 0){
        newUrl = _url.substring(0,_index);
        _httpIndex = newUrl.indexOf("http");
        if(_httpIndex >= 0){
          _url = document.location.href;
          newUrl = _url.substring(_httpIndex+7,_url.length);
          _index = newUrl.indexOf("/");
          if(_index >= 0){
            newUrl = newUrl.substring(0,_index);
          }
        }
        newUrl = "http://"+newUrl;
        return newUrl;
      }else{
        return _url;
      }
    },
    getPathByKey (value, key, arr, children){
      console.log(value);
      console.log(key);
      console.log(arr);
      let temppath = []
      try{
        function getNodePath(node){
          temppath.push(node[key]);
          if (node[key] === value) {
              throw ("GOT IT!");
          }
          if (node[children] && node[children].length > 0) {
              for (var i = 0; i < node[children].length; i++) {
                  getNodePath(node[children][i]);
              }
              temppath.pop();
          }
          else {
              temppath.pop();
          }
        }
        for (let i = 0; i < arr.length; i++) {
            getNodePath(arr[i]);
        }
        console.log(temppath);
      }
      catch (e){
        console.log(temppath);
        return temppath;
      }
    },
    /*-------------------正则-----------------*/
    //手机
    validatePhone(rule, value, callback){
      var reg = /^1\d{10}$/;
      if (value.length>0 && !reg.test(value)) {
        callback(new Error('请输入正确的手机号！'));
      } else {
        callback();
      }
    },
    //固定电话
    validateTel(rule, value, callback){
      var reg = /\d{3}-\d{8}|\d{4}-\{7,8}/;
      if (value.length>0 && !reg.test(value)) {
        callback(new Error('请输入正确的固定电话！'));
      } else {
        callback();
      }
    },
    //邮箱
    validateEmail(rule, value, callback){
      var reg = /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/;
      if(value == null){
        callback();
      }else if (value.length>0 && !reg.test(value)) {
        callback(new Error('请输入正确的邮箱！'));
      } else {
        callback();
      }
    },
    // 获取权限点
    getRightCodes: function(code) {
      let ret = false
      if(store.state.LOGINTYPE == 'store'){ //门店端菜单
        ret = true
      }else if(store.state.USER.id == 0){ //超级管理员
        ret = true
      }else{
        let arr = store.state.USER.content
        let rightArr = []
        arr.forEach((t)=>{
          if(t[2]){
            rightArr.push(t[2])
          }
        });
        ret = rightArr.includes(code)
      }
      return ret
    }
  }
}
