import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// 获取本地state
const getState = function (name,defaultVal) {
  return JSON.parse(sessionStorage.getItem(name)) || defaultVal || '';
};

// 获取本地state
const getLocalState = function (name,defaultVal) {
  return JSON.parse(localStorage.getItem(name)) || defaultVal || '';
};

// 更新state数据(插入，更新)
const changeState = function (name, state, data) {
  if (typeof data === 'undefined') return;
  sessionStorage[name] = JSON.stringify(data);
  state[name] = data;
};

// 更新state数据(插入，更新)
const changeLocalState = function (name, state, data) {
  if (typeof data === 'undefined') return;
  localStorage[name] = JSON.stringify(data);
  state[name] = data;
};

export default new Vuex.Store({
  state: {
    // TOKEN
    TOKEN:getState('TOKEN'),
    // 登录用户
    USER:getState('USER'),
    //菜单
    MENUS:getState('MENUS'),
    //登录类型
    LOGINTYPE:getState('LOGINTYPE'),
  },
  getters: {
  },
  mutations: {
    SETTOKEN: (state, data) => {
      changeState('TOKEN', state, data);
    },
    SETUSER: (state, data) => {
      changeState('USER', state, data);
    },
    SETMENUS: (state, data) => {
      changeState('MENUS', state, data);
    },
    SETLOGINTYPE: (state, data) => {
      changeState('LOGINTYPE', state, data);
    },
  },
  actions: {
  },
  modules: {
  }
})
