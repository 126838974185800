import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

//引入element
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/zh-TW";

// 自定义样式引用
import "./assets/style/reset.css";
import "./assets/style/c_index.less";

//富文本语言配置
import {
  i18nChangeLanguage,
  i18nAddResources
} from "@wangeditor/editor";
import lang from "./api/wangeditorLang";
i18nAddResources("zh-TW",lang);
i18nChangeLanguage("zh-TW");

import request from "./api/request";
Vue.prototype.$http = request; // axios 请求封装
import mixins from "./mixins";
Vue.mixin(mixins); // 注册全局mixins.

// lodash 引用
window._ = require("lodash");

Vue.use(ElementUI, { locale });

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
