import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

Vue.use(VueRouter)

const childenRouter = [
  {
    path: '/',
    name: 'home',
    component: resolve => require(['@/views/home/home'], resolve),
    meta: {title: '主頁'}
  },
  // 用户管理
  {
    path: '/userList',
    name: 'userList',
    component: resolve => require(['@/views/users/userList'], resolve),
    meta: {title: '用戶列表'}
  },
  {
    path: '/userDetails',
    name: 'userDetails',
    component: resolve => require(['@/views/users/userDetails'], resolve),
    meta: {title: '用戶詳情'}
  },
  // 诊所管理
  {
    path: '/clinicList',
    name: 'clinicList',
    component: resolve => require(['@/views/clinic/clinicList'], resolve),
    meta: {title: '診所列表'}
  },
  {
    path: '/editClinic',
    name: 'editClinic',
    component: resolve => require(['@/views/clinic/editClinic'], resolve),
    meta: {title: '新增/編輯診所'}
  },
  {
    path: '/clinicDetails',
    name: 'clinicDetails',
    component: resolve => require(['@/views/clinic/clinicDetails'], resolve),
    meta: {title: '診所詳情'}
  },
  // 医生管理
  {
    path: '/doctorList',
    name: 'doctorList',
    component: resolve => require(['@/views/doctor/doctorList'], resolve),
    meta: {title: '視光師/醫生列表'}
  },
  {
    path: '/editDoctor',
    name: 'editDoctor',
    component: resolve => require(['@/views/doctor/editDoctor'], resolve),
    meta: {title: '新增/編輯視光師/醫生'}
  },
  {
    path: '/doctorDetails',
    name: 'doctorDetails',
    component: resolve => require(['@/views/doctor/doctorDetails'], resolve),
    meta: {title: '視光師/醫生詳情'}
  },
  // 服务管理
  {
    path: '/serverList',
    name: 'serverList',
    component: resolve => require(['@/views/server/serverList'], resolve),
    meta: {title: '服務列表'}
  },
  {
    path: '/editServer',
    name: 'editServer',
    component: resolve => require(['@/views/server/editServer'], resolve),
    meta: {title: '新增/編輯服務'}
  },
  {
    path: '/serverDetail',
    name: 'serverDetail',
    component: resolve => require(['@/views/server/serverDetail'], resolve),
    meta: {title: '服務詳情'}
  },
  {
    path: '/serverSort',
    name: 'serverSort',
    component: resolve => require(['@/views/server/serverSort'], resolve),
    meta: {title: '服務分類管理'}
  },
// 预约管理
  {
    path: '/bookingList',
    name: 'bookingList',
    component: resolve => require(['@/views/booking/bookingList'], resolve),
    meta: {title: '預約列表'}
  },
  {
    path: '/addBook',
    name: 'addBook',
    component: resolve => require(['@/views/booking/addBook'], resolve),
    meta: {title: '新增預約'}
  },
  {
    path: '/bookDetail',
    name: 'bookDetail',
    component: resolve => require(['@/views/booking/bookDetail'], resolve),
    meta: {title: '預約詳情'}
  },
  // 内容管理
  {
    path: '/sortSetting',
    name: 'sortSetting',
    component: resolve => require(['@/views/content/sortSetting'], resolve),
    meta: {title: '排序展示配置'}
  },
  {
    path: '/banner',
    name: 'banner',
    component: resolve => require(['@/views/content/banner'], resolve),
    meta: {title: 'banner'}
  },
  {
    path: '/bannerAdd',
    name: 'bannerAdd',
    component: resolve => require(['@/views/content/bannerAdd'], resolve),
    meta: {title: '新增/編輯banner'}
  },
  {
    path: '/textManage',
    name: 'textManage',
    component: resolve => require(['@/views/content/textManage'], resolve),
    meta: {title: '文本管理'}
  },
  {
    path: '/textEdit',
    name: 'textEdit',
    component: resolve => require(['@/views/content/textEdit'], resolve),
    meta: {title: '文本編輯'}
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: resolve => require(['@/views/content/feedback'], resolve),
    meta: {title: '意見反饋'}
  },
  {
    path: '/evaluate',
    name: 'evaluate',
    component: resolve => require(['@/views/content/evaluate'], resolve),
    meta: {title: '評價管理'}
  },
  {
    path: '/settlement',
    name: 'settlement',
    component: resolve => require(['@/views/content/settlement'], resolve),
    meta: {title: '入駐申請'}
  },
  {
    path: '/settleDetail',
    name: 'settleDetail',
    component: resolve => require(['@/views/content/settleDetail'], resolve),
    meta: {title: '入駐申請詳情'}
  },
  {
    path: '/faq',
    name: 'faq',
    component: resolve => require(['@/views/content/faq'], resolve),
    meta: {title: '常見問題'}
  },
  {
    path: '/faqAdd',
    name: 'faqAdd',
    component: resolve => require(['@/views/content/faqAdd'], resolve),
    meta: {title: '新增/編輯常見問題'}
  },
  {
    path: '/eyeShield',
    name: 'eyeShield',
    component: resolve => require(['@/views/content/eyeShield'], resolve),
    meta: {title: '護眼知識'}
  },
  {
    path: '/eyeAdd',
    name: 'eyeAdd',
    component: resolve => require(['@/views/content/eyeAdd'], resolve),
    meta: {title: '新增/編輯護眼知識'}
  },
  // 权限管理
  {
    path: '/manageList',
    name: 'manageList',
    component: resolve => require(['@/views/authority/manageList'], resolve),
    meta: {title: '管理員列表'}
  },
  {
    path: '/roleList',
    name: 'roleList',
    component: resolve => require(['@/views/authority/roleList'], resolve),
    meta: {title: '角色列表'}
  },
  {
    path: '/roleDetail',
    name: 'roleDetail',
    component: resolve => require(['@/views/authority/roleDetail'], resolve),
    meta: {title: '角色詳情'}
  },
  {
    path: '/operation',
    name: 'operation',
    component: resolve => require(['@/views/authority/operation'], resolve),
    meta: {title: '操作日誌'}
  },
  // 基础配置
  {
    path: '/basicSetting',
    name: 'basicSetting',
    component: resolve => require(['@/views/basic/basicSetting'], resolve),
    meta: {title: '診所基礎信息配置'}
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: resolve => require(['@/views/layout/layout'], resolve),
      meta: { title: '管理後台', metarequireAuth: true },
      children: childenRouter
    },
    {
      path: '/admin',
      name: 'admin',
      component: resolve => require(['@/views/login/admin'], resolve),
      meta: { title: '登錄', metarequireAuth: false }
    },
    {
      path: '/login',
      name: 'login',
      component: resolve => require(['@/views/login/login'], resolve),
      meta: { title: '登錄', metarequireAuth: false }
    },
  ]
})

router.beforeEach((to, from, next) => {
  let token = store.state.TOKEN;
  //登录类型：admin-管理后台，store-门店端
  let loginType = store.state.LOGINTYPE
  //设置标题写入
  if(to.meta.title){
    document.title = to.meta.title || "";
  }
  NProgress.start();
  if(to.path == '/admin' || to.path == '/login'){
    next()
  }else{
    //未登录跳转
    if (!token && (to.path != `/admin` && to.path != `/login`)) {
      if(!loginType || loginType == 'store'){//默认门店端
        next({ path: `/login` })
      }else{
        next({ path: `/admin` })
      }
    } else {
      next()
    }
  }
})

router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done()
})

export default router
