const lang = {
  blockQuote: {
    title: "引用",
  },
  codeBlock: {
    title: "代碼塊",
  },
  color: {
    bgColor: "背景色",
    clear: "清除背景色",
    color: "文字顏色",
    default: "默認顏色",
  },
  common: {
    delete: "删除",
    enter: "回車",
    ok: "確定",
  },
  divider: {
    title: "分割線",
  },
  editor: {
    image: "圖片",
    indent: "縮進",
    justify: "對齊",
    more: "更多",
    video: "視頻",
  },
  emotion: {
    title: "表情",
  },
  fontFamily: {
    default: "默認字體",
    title: "字體",
  },
  fontSize: {
    default: "默認字號",
    title: "字號",
  },
  fullScreen: {
    title: "全屏",
  },
  header: {
    text: "正文",
    title: "標題",
  },
  highLightModule: {
    selectLang: "選擇語言",
  },
  image: {
    delete: "刪除圖片",
    desc: "圖片描述",
    edit: "編輯圖片",
    link: "圖片鏈接",
    netImage: "網絡圖片",
    src: "圖片地址",
    viewLink: "查看鏈接",
  },
  indent: {
    decrease: "減少縮進",
    increase: "增加縮進",
  },
  justify: {
    center: "居中對齊",
    justify: "兩端對齊",
    left: "左對齊",
    right: "右對齊",
  },
  lineHeight: {
    default: "默認行高",
    title: "行高",
  },

  link: {
    edit: "修改鏈接",
    insert: "插入鏈接",
    text: "鏈接文本",
    unLink: "取消鏈接",
    url: "鏈接地址",
    view: "查看鏈接",
  },

  listModule: {
    orderedList: "有序列表",
    unOrderedList: "無序列表",
  },
  tableModule: {
    deleteCol: "刪除列",
    deleteRow: "删除行",
    deleteTable: "删除表格",
    header: "表頭",
    insertCol: "插入列",
    insertRow: "插入行",
    insertTable: "插入表格",
    widthAuto: "寬度自適應",
  },
  textStyle: {
    bold: "粗體",
    clear: "清除格式",
    code: "行內代碼",
    italic: "斜體",
    sub: "下標",
    sup: "上標",
    through: "刪除線",
    underline: "下劃線",
  },
  todo: {
    todo: "待辦",
  },
  undo: {
    redo: "重做",
    undo: "撤銷",
  },
  uploadImgModule: {
    uploadError: "{{fileName}} 上傳出錯",
    uploadImage: "上傳圖片",
  },
  videoModule: {
    delete: "刪除視頻",
    editSize: "修改尺寸",
    height: "高度",
    insertVideo: "插入視頻",
    ok: "確定",
    uploadVideo: "上傳視頻",
    videoPoster: "視頻封面",
    videoPosterPlaceHolder: "封面圖片 url",
    videoSrc: "視頻地址",
    videoSrcPlaceHolder: "視頻文件 url 或第三方 <iframe>",
    width: "寬度",
  },
};
export default lang;
